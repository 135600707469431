<template>
    <section id="banner" class="hero-section pt-5 background-img">
        <div class="container">
            <div class="row align-items-center justify-content-between py-3">
                <div class="col-md-7 col-lg-6">
                    <div class="hero-content-left text-white d-flex flex-column" style="z-index: 2 !important; gap: 2rem">
                        <h1 class="text-white" style="line-height: 3rem;">
                            <span>O app que otimiza sua troca de óleo e potencializa seu atendimento</span>
                        </h1>
                        <p class="lead">
                            Agilize o serviço, melhore seu atendimento e digitalize a sua troca de óleo
                        </p>

                        <div class="subscribe-form">



                            <div class="d-flex align-items-center" style="width: fit-content; border-radius: 1rem; box-shadow: 5px 8px 7px 0px rgba(0,0,0,0.2); cursor: pointer">

                                
                                    <a :href="urlApp" class="solid-btn d-flex justify-content-center align-items-center" style="font-weight: bold; border-radius: .9rem; padding: 1rem; gap: 1rem; border: none; height: 100%; white-space: nowrap;">
                                        Iniciar grátis
                                    </a>
                                

                                <!-- <input
                                    type="text"
                                    id="telefone"
                                    name="phone"
                                    value=""
                                    class="form-control input"
                                    v-model="phone"
                                    @input="updatePhone"
                                    v-mask='"(##) #####-####"'
                                    placeholder="Digite seu telefone ..."
                                    required="required"
                                    style="border-radius: .9rem;"
                                />
                                <input
                                    type="button"
                                    class="button btn solid-btn"
                                    @click="showModalBase"
                                    id="submit"
                                    value="Iniciar grátis"
                                    style="border-radius: .7rem;"
                                /> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-lg-5 pb-5">
                    <div class="hero-animation-img" style="z-index: 2 !important;">
                        <img
                            src="img/mkp-main.png"
                            alt="app"
                            class="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-img-absolute" style="z-index: 1 !important;">
            <img
                src="img/hero-bg-shape-1.svg"
                alt="wave shape"
                class="img-fluid"
            />
        </div>
        <ModalBase
            v-if="showModal"
            @close="showModal = false"
        >
            This is my first modal
        </ModalBase>
    </section>
</template>
<style>
.banner-1-bg {
    background: url("../../assets/img/app-hero-bg.jpg") no-repeat center center /
        cover !important;
}
</style>
<script>
import ModalBase from "../../components/ModalBase";


export default {
    name: "Banner",   
    components: { ModalBase },
    data: function () {
        return {
            urlApp: window.urlApp+'login?passo=cadastro',
            showModal: false,
        };
    },
    computed: {
        phone: {
            get() {
                return this.$store.getters.getPhone
            },
            set(value) {
                this.$store.dispatch('updatePhone', value)
            }
        }
    },
    methods: {
        updatePhone() {
            // Aqui você pode aplicar uma máscara ou outras validações se necessário
            this.$store.dispatch('updatePhone', this.phone)
        },
        showModalBase: function (e) {
            e.preventDefault();
            this.showModal = true;
        },
    },
};
</script>
