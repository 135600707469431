<template>
    <div class="overflow-hidden">
        <section
            id="pricing"
            class="package-section gray-light-bg pb-5" style="padding-top: 8rem"
        >
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="section-heading text-center mb-5 d-flex flex-column" style="gap: 2rem">
                            <h2>
                                Conheça Nossos Planos
                            </h2>
                            <p class="lead">
                                Selecione o plano que mais combina com sua oficina e comece a aproveitar os benefícios exclusivos do iLub.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-between align-items-center">
                    <div class="col-md-12 owl-custom-nav-parent">
                        <carousel
                            v-if="array.length > 0" 
                            ref="oc"
                            :autoplay="false"
                            :dots="false"
                            class="owl-carousel owl-theme client-testimonial arrow-indicator owl-loaded owl-drag"
                            :loop="false"
                            :margin="30"
                            :nav="true"
                            :responsiveClass="true"
                            :autoplayHoverPause="false"
                            :lazyLoad="false"
                            :responsive="{
                                0: { items: 1 },
                                500: { items: 2 },
                                600: { items: 3 },
                                800: { items: 3 },
                                1200: { items: 4 },
                            }"
                            :nav-text="navText"
                        >
                        <!-- <div class="item pt-3">
                            <div style="z-index: 1 !important;" class="card text-center single-pricing-pack">
                                <div class="card-header" style="border-radius: 2em 2em 0 0;background-color: #F9F9F9;border:0px !important;">
                                    <div><h5 style="margin:0 !important;color:#FFF;">Grátis</h5></div>
                                </div>
                                <div class="card-body" style="color:#707070 !important;">
                                    <div class="h1 text-center">
                                        R$ <span class="price font-weight-bolder"
                                            >0,00</span
                                        >
                                    </div>
                                    <p>por cnpj/mês</p>
                                    <ul class="list-unstyled text-sm mt-3 pricing-feature-list">
                                        <li :class="i < 1 ? 'invisible' : ''">Valor total: R$ 0,00</li>
                                        <li >Ideal para até 10 atendimentos</li>
                                    </ul>
                                    <a :href="urlApp" class="btn outline-btn mt-3" target="_blank">Escolher</a>
                                </div>
                            </div>
                        </div> -->
                        <div v-for="(item, i) in array" :key="i" class="item pt-2">
                            <div style="z-index: 1 !important;"                                 
                                 v-bind:style="{'background-color': (item.nome == 'Balanceado' ? '#ff2b57' : '#F9F9F9')}"
                                 class="card text-center single-pricing-pack"
                            >    
                                <div class="card-header" 
                                    v-bind:style="{'background-color': (item.nome == 'Balanceado' ? '#ff2b57' : '#F9F9F9')}"
                                    style="border-radius: 2em 2em 0 0;border:0px !important;">
                                    <div><h5 v-bind:style="{'color': (item.nome == 'Balanceado' ? '#FFF !important' : '#464E5F')}" style="margin:0 !important;">{{ item.nome }}</h5></div>
                                </div>
                                <div class="card-body" style="border-radius: 1.5em;color:#707070 !important; border-top:solid 2px #F1F1F1 !important;background-color: #FFF !important;">
                                    <div class="h1 text-center pb-3">
                                        R$ <span class="price font-weight-bolder"
                                            >{{ calculaPrecoPorMes(item.validade, item.valor) }}</span
                                        >
                                    </div>
                                    <p style="font-size: medium;" class="pb-3">por cnpj/mês</p>
                                    <p style="font-size: medium;" class="pb-3">{{ item.descricao }}</p>
                                    <div class="text-left">
                                        <p style="font-size: small;"><img src="img/check-green.svg" style="display:inline; width:10px !important;" class="img-fluid mr-1"/>{{ item.validade }} dias</p>
                                        <p style="font-size: small;"><img src="img/check-green.svg" style="display:inline; width:10px !important;" class="img-fluid mr-1"/>{{ item.limite_whatsapp }} envios por whatsapp</p>
                                        <p style="font-size: small;"><img src="img/check-green.svg" style="display:inline; width:10px !important;" class="img-fluid mr-1"/>{{ item.limite_buscaPlaca }} buscas pela placa</p>
                                    </div>
                                    <a :href="`${urlApp}${item.id}`" class="btn outline-btn mt-3" target="_blank">Escolher</a>
                                    
                                </div>
                            </div>
                        </div>
                        <!-- <div class="item pt-2">
                            <div style="z-index: 1 !important;background-color: #F9F9F9 !important;" class="card text-center single-pricing-pack">
                                <div class="card-header" style="border-radius: 2em 2em 0 0;background-color: #F9F9F9;
                                border:0px !important;">
                                    <div><h5 style="margin:0 !important;color:#FFF;">Personalizado</h5></div>
                                </div>
                                <div class="card-body" style="border-radius: 2em;color:#707070 !important;
                                border-top:solid 2px #F1F1F1 !important;background-color: #FFF !important;">
                                    <div class="h1 text-center pb-3">
                                        <span class="price font-weight-bolder"
                                            >Negociar</span
                                        >
                                    </div>
                                    <p style="font-size: medium;" class="pb-3">Totalmente personalizado</p>
                                    <a :href="urlApp" class="btn outline-btn mt-3" target="_blank">Escolher</a>
                                   
                                </div>
                            </div>
                        </div> -->

                          
                        </carousel>
                    </div>
                </div>

                <!-- <div class="row justify-content-center">
                    
                    <div v-for="(item, i) in array" :key="i" class="col-lg-4 col-md">
                        <div style="z-index: 1 !important;" class="card text-center single-pricing-pack">
                            <div class="card-header" style="border-radius: 2em 2em 0 0;background-color: #000033;border:0px !important;">
                                <div><h5 style="margin:0 !important;color:#FFF;">{{ item.nome }}</h5></div>
                            </div>
                            <div class="card-body" style="color:#707070 !important;">
                                <div class="h1 text-center mb-0">
                                    R$ <span class="price font-weight-bolder"
                                        >{{ calculaPrecoPorMes(item.validade, item.valor) }}</span
                                    >
                                </div>
                                <p>por cnpj/mês</p>
                              
                                <a
                                    :href="urlApp"
                                    class="btn outline-btn mt-4 mb-3"
                                    target="_blank"
                                    >Quero esse</a
                                >
                                <ul
                                    class="list-unstyled text-sm mb-3 pricing-feature-list"
                                >
                                    <li :class="i < 1 ? 'invisible' : ''">Valor total: R$ {{ item.valor.replace(",","").replace(".",",") }}</li>
                                    <li >{{ item.descricao }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
        
                </div> -->
            </div>
        </section>
    </div>
</template>

<script>

import carousel from "vue-owl-carousel";

export default {
    name: "Pricing",
    isGray: {
        type: Boolean,
        default: false,
    },
    isPrimary: {
        type: Boolean,
        default: false,
    },

    data: function () {
        return {
            navText: ["‹", "›"],
            urlApp: window.urlApp+'login?passo=cadastro&redirect=plano&idPlano=',
            array: [],
            i: 0,
            calculaPrecoPorMes(dias, valor) {

                const meses = dias / 30

                const resultado = Number(valor) / meses

                return String(resultado.toFixed(2)).replace(".", ",")

            },
        };
    },
    components: { carousel },

    methods: {

        async buscarPrecos() {

            let response = await fetch(`${window.url}plano?tipo=site`)

            const { data } = await response.json()

            console.log(data);
            this.array = data

        },
    },
    mounted() {
        this.buscarPrecos();
    }
   
};
</script>

<style >


.owl-custom-nav-parent .owl-carousel .owl-nav .owl-next:hover {
    color: #ffffff !important;
}
.owl-custom-nav-parent .owl-carousel .owl-nav .owl-prev:hover {
    color: #ffffff !important;
}
.owl-custom-nav-parent .owl-nav {
    position: relative !important;
}
</style>