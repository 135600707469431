<template>
    <div>
        <nav-bar />
        <div class="main">
            <banner />
            <pricing  />
            <PlanoPersonalizado  />
            <questions />
            <site-footer />
        </div>
    </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import Banner from "../../views/index-one/BannerPlanos";
import Pricing from "../../views/index-one/PricingPlanos";
import PlanoPersonalizado from "../../views/index-one/PlanoPersonalizado";
import Questions from "../../views/index-one/Questions";
import SiteFooter from "../../views/commons/SiteFooter";

// import Screenshots from "../../views/commons/Screenshots";

export default {
    name: "IndexOne",
    components: {
        NavBar,
        Banner,
        Pricing,
        PlanoPersonalizado,
        Questions,
        SiteFooter,
    },
};
</script>