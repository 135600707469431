<template>
    <div class="overflow-hidden">
        <section
            id="questions"
            class="package-section pb-5 pt-5"
        >
            <div class="container">
                <div class="row mt-5">
                    <div class="col-lg-12">
                        <accordian id="accordion-1" :contents="contents" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Accordian from "../../components/Accordian";
export default {
    name: "Questions",
    isGray: {
        type: Boolean,
        default: false,
    },
    isPrimary: {
        type: Boolean,
        default: false,
    },
    components: {
        Accordian,
    },
    data: function () {
        return {
            contents: [
                {
                    title: "Como faço para criar uma conta no iLub?",
                    description:
                        "Para criar sua conta, basta clicar no botão \"Inscreva-se\" no site ou baixar o app. Preencha o formulário com seu nome, documento, telefone, e-mail e crie uma senha. Acesso liberado imediatamente! ",
                    active: false,
                    icon: "img/user-alt.svg",
                    backgroundColor: "white",
                },

                {
                    title: "Como faço para consultar os dados do manual técnico de um veículo no app?",
                    description:
                        "No app, você pode consultar o manual técnico do veículo de três formas: digitando a placa, ou o modelo do veículo, ou selecionando a montadora, ano e modelo. Todas as opções mostram as indicações corretas de óleo e filtros.",
                    active: false,
                    icon: "img/search.svg",
                    backgroundColor: "white",
                },
                {
                    title: "Como alterar as marcas que aparecem na recomendação de produtos?",
                    description:
                        "Abra o menu no canto superior esquerdo, vá até \"Configurações\" e selecione os produtos com as marcas configuradas. Escolha a marca que deseja que apareça primeiro nas recomendações.",
                    active: false,
                    icon: "img/redo-alt-duotone.svg",
                    backgroundColor: "white",
                },
                {
                    title: "Como gerar a etiqueta de troca ao final da revisão?",
                    description:
                        "Após registrar a troca na tela de produtos indicados, clique em \"Registrar Troca\". Preencha os dados do atendimento e, ao final, clique em \"Gerar Etiqueta\" para visualizar e imprimir a etiqueta de troca de óleo",
                    active: false,
                    icon: "img/print.svg",
                    backgroundColor: "white",
                },
                {
                    title: "Posso compartilhar assinatura?",
                    description:
                        "Não, a assinatura do iLub é exclusiva para 1 usuário. Caso precise de algum atendimento especial, entre em contato conosco.",
                    active: false,
                    icon: "img/unlock-alt-close-duotone.svg",
                    backgroundColor: "white",
                },
                {
                    title: "Posso cancelar a assinatura do app?",
                    description:
                        "Se a renovação não ocorrer, a assinatura será cancelada automaticamente, de forma simples e sem burocracia.",
                    active: false,
                    icon: "img/info.svg",
                    backgroundColor: "white",
                },
            ],
        };
    },
    mounted() {
    }
};
</script>

<style>
</style>