<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// window.url = "https://hml.api.ilub.app/api/v3/";
window.url = "https://api.ilub.app/api/v3/";
// window.url = "http://localhost:8000/api/v3/";



// window.urlApp = "https://purple-tree-0da74f410-homologacao.centralus.4.azurestaticapps.net/";//lembrar de deixar o barra no final
window.urlApp = "https://trocadeoleo.ilub.app/";//lembrar de deixar o barra no final
// window.urlApp = "http://localhost:8100/";//lembrar de deixar o barra no final

export default {};
</script>

<style lang = "css">
</style>
