<template>
    <section class="download-section pt-5 pb-5" style="background-image: linear-gradient(to right, #000033, #02021D) !important;">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-7">
                    <div class="download-content text-white d-flex flex-column" style="gap: 2rem">
                        <h2 class="text-white">
                            Use o iLub no seu celular. <br> Comece Grátis!
                        </h2>
                        <p class="lead">
                            Disponível em todas as plataformas digitais. 
                            Com o iLub você tem a ferramenta certa para 
                            sua oficina e troca de óleo na palma da 
                            mão em qualquer lugar. 
                        </p>

                        <p>Baixe o App, crie sua conta agora mesmo e <b style="color: #FF2B57 !important">ganhe 7 dias grátis</b> para experimentar o app!</p>

                        <section class="d-flex" style="gap: 1.3rem; width: fit-content;">

                            <div class="d-flex flex-column" style="">
    
        
                                <div class="download-btn pb-4">
                                    <a href="https://play.google.com/store/apps/details?id=br.com.camptecnologia.ilub&hl=pt_BR" class="btn google-play-btn">
                                        <img
                                            src="img/android.png"
                                            alt="Google Play"
                                            style="width: 40px;height: 40px;display: inline-block;vertical-align: middle;"
                                        />
                                        <p class="mb-0" style="display: inline;">Google Play</p>
                                    </a>
                                </div>
                                <div class="download-btn pb-5">
                                    <a href="https://apps.apple.com/br/app/ilub/id6484319693" class="btn app-store-btn">
                                        <img
                                            src="img/apple.png"
                                            alt="App Store"
                                            style="width: 40px;height: 40px;display: inline-block;vertical-align: middle;"
                                        />
                                        <p class="mb-0" style="display: inline;">App Store</p>
                                    </a>
                                </div>
    
                            </div>

                            <div class="d-flex flex-column" style="gap: 1rem; width: 8rem;">

                                <img style="height: 8rem; border-radius: .2rem;" :src="`img/qrcode.png`" />

                                <span style="text-align: center; line-height: 1.2;">Baixe agora e comece a usar!</span>

                            </div>
                        </section>


                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="download-img d-flex align-items-center">
                        <img
                            src="img/mecanico.png"
                            alt="download"
                            class="removeImg"
                            style="position: absolute !important;width: 400px;top: -328px;right: -5px;"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Download",
    data: () => {
        return {
            detectaSistemaOperacional() {

                const userAgent = navigator.userAgent || navigator.vendor || window.opera

                if(/android/i.test(userAgent)) return "Android";

                return 'ios'

            }
        }
    }
};
</script>

<style>
.download-1-bg {
    background: url("../../assets/img/app-hero-bg.jpg") no-repeat center center /
        cover !important;
}
</style>