var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('nav',{staticClass:"navbar navbar-expand-lg fixed-top",class:{
            affix: _vm.hasAffix,
            'custom-nav': _vm.coloredLogo,
            'bg-transparent': !_vm.coloredLogo,
            'white-bg': _vm.coloredLogo,
        },style:({'background': (_vm.openMenu ? 'linear-gradient(to right, #000033, #02021D)' : 'transparent')})},[_c('div',{staticClass:"container"},[_vm._m(0),_c('button',{staticClass:"navbar-toggler",class:{ collapsed: _vm.collapsed },attrs:{"type":"button"},on:{"click":_vm.mobileNavClicked}},[_c('span',{staticClass:"ti-menu"})]),_c('div',{staticClass:"collapse navbar-collapse main-menu h-auto",class:{ show: !_vm.collapsed },attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav ml-auto"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#pricing'),expression:"'#pricing'"}],staticClass:"nav-link page-scroll pb-2 pt-2",attrs:{"to":"/planos"}},[_vm._v("Planos")])],1),_vm._m(1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../../public/img/logo.svg"),"width":"75","alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link page-scroll outline-btn-app pb-2 pt-2 pr-2 pl-2",staticStyle:{"border-radius":"20px !important"},attrs:{"target":"_blank","href":"https://trocadeoleo.ilub.app/"}},[_vm._v("Ir para o App ")])])
}]

export { render, staticRenderFns }